/** @jsx jsx */
import { jsx, Styled, Textarea } from 'theme-ui';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import { Container } from '../components';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import DropzoneField from '../components/DropzoneField';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import * as analytics from '../utils/analytics';

const scamMessageSchema = translate =>
  yup.object().shape({
    type: yup.string().required(translate('scamMessages.message.requiredField')),
    typeDescription: yup.string().when('type', {
      is: 'other',
      then: yup.string().required(translate('scamMessages.message.requiredField')),
      otherwise: yup.string(),
    }),
    name: yup.string().required(translate('scamMessages.message.requiredField')),
    email: yup
      .string()
      .email(translate('scamMessages.message.invalidEmail'))
      .required(translate('scamMessages.message.requiredField')),

    phone: yup.string().when('email', {
      is: email => !email,
      then: yup.string().required(translate('scamMessages.message.requiredField')),
      otherwise: yup.string().nullable(),
    }),

    feedback: yup.string().required(translate('scamMessages.message.requiredField')),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(2, translate('scamMessages.message.tooManyFiles', { files: 2 }))
      .ensure(),
    recaptcha: yup.string().required(),
  });

const ScamMessagesForm = ({ translate, handleSubmit, isSubmitting, setFieldValue, errors, values, nocaptcha }) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField name="name" label={translate('scamMessages.name')} />
      <FormField name="email" label={translate('scamMessages.email')} />
      <FormField name="phone" label={translate('scamMessages.phone')} />

      <RadioFieldGroup id="type" error={errors.type} label={translate('scamMessages.type')}>
        <Field
          component={RadioField}
          name="type"
          id="sms"
          value={translate('scamMessages.types.sms')}
          label={translate('scamMessages.types.sms')}
        />
        <Field component={RadioField} name="type" id="call" label={translate('scamMessages.types.call')} />
        <Field component={RadioField} name="type" id="email" label={translate('scamMessages.types.email')} />
        <Field
          component={RadioField}
          name="type"
          id="marketplace"
          value={translate('scamMessages.types.marketplace')}
          label={translate('scamMessages.types.marketplace')}
        />
        <Field
          component={RadioField}
          name="type"
          id="app"
          label={translate('scamMessages.types.app')}
          value={translate('scamMessages.types.app')}
        />
        <Field
          component={RadioField}
          name="type"
          id="other"
          label={translate('scamMessages.types.other')}
          value={translate('scamMessages.types.other')}
        />
      </RadioFieldGroup>
      {values.type === translate('scamMessages.types.other') && (
        <FormField name="typeDescription" as={Textarea} label={translate('scamMessages.typeDescription')} rows={4} />
      )}

      <FormField
        name="feedback"
        as={Textarea}
        placeholder={translate('scamMessages.feedbackPlaceholder')}
        label={translate('scamMessages.feedback')}
        rows={4}
      />
      <DropzoneField
        name="attachment"
        label={translate('scamMessages.attachment')}
        placeholder={translate('scamMessages.dropzoneText')}
        clearButtonText={translate('scamMessages.clear')}
        zoneActiveText={translate('scamMessages.dropzoneActiveText')}
        newLabel={translate('scamMessages.feedbackAttachmentSize', { maxSize: 5 })}
      />
      <RecaptchaButton
        buttonText="scamMessages.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const ScamMessageFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      type: vo.type || '',
      name: vo.name || '',
      email: vo.email || '',
      phone: vo.phone || '',
      typeDescription: vo.typeDescription || '',
      feedback: vo.feedback || '',
      attachment: vo.attachment || [],
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return scamMessageSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'ScamMessagesInputForm',
})(ScamMessagesForm);

const ScamMessagesPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendScamMessageForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: { message: translate('scamMessages.message.success') },
          })
        );
        dispatch(showNotification('scamMessages.message.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('scamMessages.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout title={translate('scamMessages.title')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('scamMessages.title')}</Styled.h1>
        <p sx={{ py: 2 }}>{translate('scamMessages.text')}</p>

        <ScamMessageFormik key={locale} {...formProps} />
        <p
          dangerouslySetInnerHTML={{
            __html: translate('scamMessages.privacy'),
          }}
          sx={{ a: { textDecoration: 'underline' } }}
        ></p>
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default ScamMessagesPage;
